.pricing {

	&.has-bg-color-cut {
		position: relative;
		background-color: transparent;
		z-index: auto;
		
		&::before {
			content: '';
			width: 100%;
			height: 300px;
			position: absolute;
			left: 0;
			bottom: 0;
			background: rgb(68, 34, 119);
			z-index: -3;
		}
	}    
}

.pricing-item-header {
    @include divider(after);

	&::after {
		max-width: 88px;
	}
}

.pricing-item-price-currency {
	color: color(base);
}

.pricing-item-price-currency,
.pricing-item-features-title {
    font-weight: 500;
}

ul.pricing-item-features-list {
	@include divider(after);

	&::after {
		background: rgba(get-color(light, 3), .32);
	}

	li {
		margin-bottom: 0;
		padding: 14px 0;
		@include divider(before);

		&::after {
			content: '';
			display: block;
			width: 24px;
			height: 24px;
			margin-right: 12px;
			background-image: inline-svg('<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M5 11h14v2H5z" fill="' + get-color(dark, 3) + '" fill-rule="nonzero"/></svg>');
            background-repeat: no-repeat;
            order: -1;
		}

		&.is-checked {

			&::after {
				background-image: inline-svg('<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><g fill-rule="nonzero" fill="none"><circle fill="' + get-color(alert, success) + '" cx="12" cy="12" r="12"/><path fill="' + get-color(light, 1) + '" d="M10.5 12.267l-2.5-1.6-1 1.066L10.5 16 17 9.067 16 8z"/></g></svg>');
			}
		}
	}
}