.button {
    background: linear-gradient(0deg, get-color(light, 3) 1px, get-color(light, 1) 2px, get-color(light, 1) 100%);
    background-origin: border-box;
    
    &:hover {
        background: linear-gradient(0deg, darken(get-color(light, 3), 1%) 1px, darken(get-color(light, 1), 1%) 2px, darken(get-color(light, 1), 1%) 100%);
        background-origin: border-box;
    }
        
    box-shadow: 0 2px 4px rgba(get-color(dark, 1), .32),
                0 8px 12px rgba(get-color(dark, 1), .24);

    &:active {
        box-shadow: 0 1px 2px rgba(get-color(dark, 1), .32),
                    0 8px 12px rgba(get-color(dark, 1), .24);
	}                
}

.button-dark {
    background: linear-gradient(0deg, darken(get-color(dark, 1), 1%) 1px, get-color(dark, 1) 2px, get-color(dark, 2) 100%);
    background-origin: border-box;
    
    &:hover {
        background: linear-gradient(0deg, get-color(dark, 1) 1px, lighten(get-color(dark, 1), 1%) 2px, lighten(get-color(dark, 2), 1%) 100%);
        background-origin: border-box;
	}
}

.button-primary {
    background: rgb(68, 34, 119);
    background-origin: border-box;
    
    &:hover {
        background: rgb(79, 51, 122);
        background-origin: border-box;
	}
}

.button-secondary {
    background: linear-gradient(0deg, get-color(secondary, 3) 1px, get-color(secondary, 1) 2px, get-color(secondary, 2) 100%);
    background-origin: border-box;
    
    &:hover {
        background: linear-gradient(0deg, lighten(get-color(secondary, 3), 1%) 1px, lighten(get-color(secondary, 1), 1%) 2px, lighten(get-color(secondary, 2), 1%) 100%);
        background-origin: border-box;
	}
}