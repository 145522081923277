.testimonial-item-image {

	img {
		border-radius: 50%;
	}
}

.testimonial-item-name {
	color: get-color(dark, 1);
}

.testimonial-item-footer {
    padding-top: 20px;
}

.testimonial-item-link {
	color: get-color(primary, 1);

	a {
        color: get-color(primary, 1);
        text-decoration: none;

        &:hover {
            color: get-color(primary, 2);
        }
	}
}