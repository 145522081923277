.form-switch {

	.form-switch-icon {
		background: linear-gradient(0deg, get-color(dark, 3) 0, lighten(get-color(dark, 3), 6%) 100%);
	}

	input:checked {

		+ .form-switch-icon {
            background: linear-gradient(0deg, get-color(primary, 1) 0, get-color(primary, 2) 100%);
        }
    }
}