[class*=illustration-] {
	position: relative;

	&::after {
		pointer-events: none;
	}
}

[class*=illustration-element-]::after {
	z-index: -1;
}

[class*=illustration-section-]::after {
	z-index: -2;
}

// Hero illustration
.illustration-section-01::after {
	@include illustration('illustration-section-01.svg', 100%, 870px, top);
}

// Gradient for sections
.gradient-section {
	background: linear-gradient(to bottom, rgba(get-color(light, 3), 0) 0%, rgba(get-color(light, 3), 1) 100%);
	z-index: 0 !important;
}